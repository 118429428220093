import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

export const firebaseConfig = {
    apiKey: "AIzaSyCROnKCTiykL5yE1uR8C1TGhW5FViF7hto",
    authDomain: "flashfood-41f66.firebaseapp.com",
    projectId: "flashfood-41f66",
    storageBucket: "flashfood-41f66.appspot.com",
    messagingSenderId: "27606339615",
    appId: "1:27606339615:web:58f41f4a9cbda02ba1052a",
}
const app = initializeApp(firebaseConfig);
// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.



export const messaging  = getMessaging(app);
export const vapidKey = 'BMCXes9pWkApyfuO_Q8zZNwQtPRxPzu2xhGPG5XU69KKyWXSubteQtvdttTzkPJinuTI4CbzwPerfmG1_yWmXbw';