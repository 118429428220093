// src/App.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import React, { useEffect, useState } from 'react';
import './App.css';
import { firebaseConfig, messaging, vapidKey } from "./firebase";
import { Button, Container, Row, Card, Col } from 'react-bootstrap';

function App() {
	const [_message, setMessage] = useState('')
	const [items, setItems] = useState([])
	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/firebase-messaging-sw.js')
				.then((registration) => {
					console.log('Service Worker registered with scope:', registration.scope);
					setMessage('Service Worker registered')
				})
				.catch((error) => {
					console.error('Service Worker registration failed:', error);
					console.error('Error name:', error.name);
					console.error('Error message:', error.message);
					console.error('Error stack:', error.stack);
				});
		} else {
			console.log('Service workers are not supported in this browser.');
		}
		onMessage(messaging, (payload) => {
			console.log('Message received. ', payload);
			setMessage('Message received. ' + payload)
		})
	}, []);

	const sendStringToBackend = async (token) => {
		try {
			const response = await fetch(`${process.env.SERVER_URL || "http://api.grocerybc.com:5007"}/token`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ token }),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			console.log('Response from backend:', data);
			setMessage('String sent successfully to backend');
		} catch (error) {
			console.error('Error sending string to backend:', error);
			setMessage('Error sending string to backend');
		}
	};

	useEffect(() => {
		initializeApp(firebaseConfig);

		const messaging = getMessaging();

		getToken(messaging, { vapidKey }).then((currentToken) => {
			if (currentToken) {
				console.log(currentToken)
				sendStringToBackend(currentToken)
			} else {
				// Show permission request UI
				console.log('No registration token available. Request permission to generate one.');
				// ...
			}
		}).catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			// ...
		});
		onMessage(messaging, (payload) => {
			console.log('Message received. ', payload);
		});
	}, [])

	const handleFetchItem = () => {
		fetch(`${process.env.SERVER_URL || "http://api.grocerybc.com:5007"}/items`)
			.then(response => response.json())
			.then(data => {
				setItems(data);
			})
			.catch(error => {
				console.error('Error fetching items:', error);
			});
	}
	return (
		<div className="App">
			<header className="App-header">
				<Button onClick={handleFetchItem}>Fetch Item</Button>
				{items.length > 0 ? (
					<Container>
						{items.map((item, idx) => (
							<Row key={idx}>
								<Col xs={2}>
									<Card.Img src={item.image_url} className="h-100 object-fit-cover" />
								</Col>
								<Col xs={10}>
									<div className='text-start'>
										<h4>{item.name_en}</h4>
										<p>
											{item.description_en}
										</p>
										<p className="text-muted">
											<h5 className="danger">${item.discounted_price.toFixed(2)}</h5>
										</p>
										<div><h6>Best Before: {item.best_before_date}</h6></div>
										<div><h6>Available: {item.available_qty}</h6></div>
									</div>
								</Col>
							</Row>
						))}
					</Container>
				) : (
					<p>No items to display</p>
				)}
				{_message}
			</header>
		</div>
	);
}

export default App;
